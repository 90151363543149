import request from '@/services/request';
import { createFormData } from '@/utils/utils';

// 分页获取用户信息
export async function userMemberList(params) {
  return await request({
    url: `/api/user/member/find`,
    method: 'post',
    data: createFormData(params),
  });
}

// 获取一条用户信息
export async function userMemberGet(params) {
  return await request({
    url: `/api/user/member/get`,
    method: 'post',
    data: createFormData(params),
  });
}

// 添加用户信息
export async function userMemberSave(params) {
  return await request({
    url: `/api/user/member/save`,
    method: 'post',
    data: createFormData(params),
  });
}

// 修改用户信息
export async function userMemberUpdate(params) {
  return await request({
    url: `/api/user/member/update`,
    method: 'post',
    data: createFormData(params),
  });
}

// 会员续费
export async function userMemberRenew(params) {
  return await request({
    url: `/api/user/member/renew`,
    method: 'post',
    data: params,
  });
}

// 普通用户升级为会员
export async function userMemberChangerole(params) {
  return await request({
    url: `/api/user/member/changeRole`,
    method: 'post',
    data: params,
  });
}
