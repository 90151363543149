import {
  createBackMusicOrder,
  musicOrderList,
  memberList,
  queryMemberBack,
  incomeList,
  incomeDetail,
  exportIncome,
  refundMoney,
  getAuthPprotocol
} from '@/services/financialManage';
import { message } from 'antd';
import { downLoad } from '@/utils/utils';

const Model = {
  namespace: 'financial',
  state: {
  },
  effects: {
    *createBackMusicOrder({ payload }, { call }) {
      // 创建音乐订单
      try {
        const response = yield call(createBackMusicOrder, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *musicOrderList({ payload }, { call }) {
      // 创建音乐订单
      try {
        const response = yield call(musicOrderList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *memberList({ payload }, { call }) {
      // 创建音乐订单
      try {
        const response = yield call(memberList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *queryMemberBack ({ payload }, { call }) {
      // 创建音乐订单
      try {
        const response = yield call(queryMemberBack, payload);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *incomeList ({ payload }, { call }) {
      // 收入列表
      try {
        const response = yield call(incomeList, payload);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },

    *incomeDetail ({ payload }, { call }) {
      // 收入明细详情列表
      try {
        const response = yield call(incomeDetail, payload);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *exportIncome ({ payload }, { call }) {
      // 收入明细详情列表
      try {
        const response = yield call(exportIncome, payload);
        console.log('respones', response);
        downLoad(response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *refundMoney({ payload }, { call }) {
      // 退款
      try {
        const response = yield call(refundMoney, payload);
        console.log('respones', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *getAuthPprotocol({ payload }, { call }) {
      // 查看授权协议
      try {
        const response = yield call(getAuthPprotocol, payload);
        console.log('respones', response);

        return response.data || {};
      } catch ( err ) {
        message.warn(err);
      }
    },

  },
  reducers: {
    setData(state, action) {
      return {
        ...state,
        [action.payload.dataName]: action.payload.data,
      };
    },
  },
};
export default Model;
