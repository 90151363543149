import request from '@/services/request';
import { createFormData , createSearch} from '@/utils/utils';

// 获取音乐列表
export async function musicList(params) {
  return request({
    url: `/api/music/find`,
    method: 'post',
    data: params,
  });
}
// 后台分页查询音乐
export async function queryMusicList(params) {
  return request({
    url: `/api/music/queryBack`,
    method: 'post',
    data: params,
  });
}

// 音乐提交申请
export async function musicApply(params) {
  return request({
    url: `/api/music/save`,
    method: 'post',
    data: {

      ...params,
      duration: 10

    },
  });
}
// 设置音乐上下架状态
export async function setMusicStatus(params) {
  return request({
    url: `/api/music/setStatus`,
    method: 'post',
    data: createFormData(params),
  });
}

// 设置音乐排列序号
export async function setMusicOrderNum(params) {
  return request({
    url: `/api/music/set_top`,
    method: 'post',
    data: createFormData(params),
  });
}

// 设置音乐是否置顶
export async function setMusicTopStatus(params) {
  return request({
    url: `/api/music/cancel_top`,
    method: 'post',
    data: createFormData(params),
  });
}

// 设置音乐报价
export async function setMusicPrice(params) {
  return request({
    url: `/api/music/setPrice`,
    method: 'post',
    data: params,
  });
}

// 获取版权方列表
export async function copyRightOwnerList(params) {
  return request({
    url: `/api/music/copyRightOwner/find`,
    method: 'post',
    data:params,
  });
}
// 添加版权方信息
export async function addCopyRightOwner(params) {
  return request({
    url: `/api/music/copyRightOwner/save`,
    method: 'post',
    data: params
  });
}
// 根据ID获取版权方信息
export async function getCopyRightOwnerById(params) {
  return request({
    url: `/api/music/copyRightOwner/get`,
    method: 'post',
    data: createFormData(params)
  });
}
// 内部音乐人列表
export async function musiciansList(params) {
  return request({
    url: `/api/music/musicians/find`,
    method: 'post',
    data: params
  });
}

// 添加音乐人信息
export async function addMusicians(params) {
  return request({
    url: `/api/music/musicians/save?attachIds=${params.attachIds}`,
    method: 'post',
    data: params
  });
}

// 根据ID获取音乐人信息
export async function getMusiciansById(params) {
  return request({
    url: `/api/music/musicians/get`,
    method: 'post',
    data: createFormData(params)
  });
}

// 根据音乐ID获取音乐授权信息
export async function getMusicPrice(params) {
  return request({
    url: `/api/music/music_price?${createSearch(params)}`,
    method: 'get'
  });
}

// 合作创作者列表
export async function composerList(params) {
  return request({
    url: `/api/music/composer/find`,
    method: 'post',
    data: params
  });
}
// 添加字典接口
export async function addDictionary(params) {
  return request({
    url: `/api/system/dictionary/save`,
    method: 'post',
    data: {
      ...params
    }
  });
}
// 获取字典分页数据

export async function findDictionary(params) {
  return request({
    url: `/api/system/dictionary/find?${createSearch(params)}`,
    method: 'post',
    data: {
      ...params,
    }
  });
}

// 获取全部音乐人
export async function findAllMusicians() {
  return request({
    url: `/api/music/musicians/findAll`,
    method: 'post',
    data: {
    }
  });
}

// 获取全部合作创作者
export async function findAllComposer() {
  return request({
    url: `/api/music/composer/findAll`,
    method: 'post',
    data: {
    }
  });
}

// 获取音乐详情
export async function getMusicDetail(params) {
  return request({
    url: `/api/music/getMusic`,
    method: 'post',
    data: createFormData(params)
  });
}
// 编辑音乐信息

export async function updateMusic(params) {
  return request({
    url: `/api/music/update`,
    method: 'post',
    data: params
  });
}

// 编辑版权方
export async function updateCopyRightOwner(params) {
  return request({
    url: `/api/music/copyRightOwner/update`,
    method: 'post',
    data: params
  });
}

// 获取所有版权方
export async function findAllCopyRightOwner(params) {
  return request({
    url: `/api/music/copyRightOwner/findAll`,
    method: 'post',
    data: params
  });
}

// 编辑音乐人
export async function updateMusicians(params) {
  return request({
    url: `/api/music/musicians/update?attachIds=${params.attachIds}`,
    method: 'post',
    data: params
  });
}

// 编辑字典
export async function updateDictionary(params) {
  return request({
    url: `/api/system/dictionary/update`,
    method: 'post',
    data: params
  });
}

// 根据ID获取字典详情
export async function findDictionaryById(params) {
  return request({
    url: `/api/system/dictionary/findById`,
    method: 'post',
    data: params
  });
}

// 设置字典的状态
export async function setDictionaryStatus(params) {
  return request({
    url: `/api/system/dictionary/setStatus`,
    method: 'post',
    data: createFormData(params)
  });
}

// 获取音乐类型/音乐情绪
export async function findMusicTpeMood(params) {
  return request({
    url: `/api/system/dictionary/find/${params.type}`,
    method: 'post'
  });
}

// 审核音乐创作者
export async function reviewComposter(params) {
  return request({
    url: `/api/music/composer/review`,
    method: 'post',
    data: createFormData(params)
  });
}

// 音乐审核
export async function setMusicAudit(params) {
  return request({
    url: `/api/music/setMusicAudit`,
    method: 'post',
    data: params
  });
}

// 获取一条合作创作者
export async function getComposer(params) {
  return request({
    url: `/api/music/composer/get`,
    method: 'post',
    data:  createFormData(params)
  });
}

// 生成授权协议
export async function getAuth_protocol(params) {
  return request({
    url: `/api/music/auth/auth_protocol`,
    method: 'post',
    data: params
  });
}

// 柚汁站点
export async function queryStatistic() {
  return request({
    url: `/api/system/oprtLog/queryHead`,
    method: 'post'
  });
}

// 修改合作创作者
export async function changeCoCreator(params) {
  return request({
    url: `/api/music/composer/update`,
    method: 'post',
    data: params
  });
}