import request from '@/services/request';
import { createFormData } from '@/utils/utils';

// 获取资源位数据
export async function recommendList(params) {
  return await request({
    url: `/api/recommend/findBack`,
    method: 'post',
    data: createFormData(params),
  });
}

// 获取一条资源位数据
export async function recommendGet(params) {
  return await request({
    url: `/api/recommend/get`,
    method: 'post',
    data: createFormData(params),
  });
}

// 添加资源位信息
export async function recommendSave(params) {
  return await request({
    url: `/api/recommend/save`,
    method: 'post',
    data: createFormData(params),
  });
}

// 修改资源位状态
export async function recommendSetstatus(params) {
  return await request({
    url: `/api/recommend/setStatus`,
    method: 'post',
    data: createFormData(params),
  });
}

// 修改资源位信息
export async function recommendUpdate(params) {
  return await request({
    url: `/api/recommend/update`,
    method: 'post',
    data: createFormData(params),
  });
}
