import {
  userMemberList,
  userMemberGet,
  userMemberSave,
  userMemberRenew,
  userMemberUpdate,
  userMemberChangerole,
} from '@/services/userManage';
// import { message } from 'antd';

const Model = {
  namespace: 'userManage',
  state: {},
  effects: {
    *userMemberList({ payload }, { call }) {
      // 获取用户列表
      try {
        const response = yield call(userMemberList, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userMemberGet({ payload }, { call }) {
      // 根据主键值获取用户信息
      try {
        const response = yield call(userMemberGet, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userMemberSave({ payload }, { call }) {
      // 添加用户信息
      try {
        const response = yield call(userMemberSave, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userMemberRenew({ payload }, { call }) {
      // 会员续费
      try {
        const response = yield call(userMemberRenew, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userMemberUpdate({ payload }, { call }) {
      // 修改用户信息
      try {
        const response = yield call(userMemberUpdate, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userMemberChangerole({ payload }, { call }) {
      // 普通用户升级为会员
      try {
        const response = yield call(userMemberChangerole, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
  },
  reducers: {
    setData(state, action) {
      return {
        ...state,
        [action.payload.dataName]: action.payload.data,
      };
    },
  },
};
export default Model;
