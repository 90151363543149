import request from '@/services/request';
import { createFormData, createSearch } from '@/utils/utils';

// 创建订单
export async function createBackMusicOrder(params) {
  return request({
    url: `/api/order/createBackMusicOrder`,
    method: 'post',
    data: params,
  });
}
// 订单列表
export async function musicOrderList (params) {
  return request({
    url: '/api/order/find',
    method: 'post',
    data: params,
  });
}
// 会员订单

export async function memberList (params) {
  return request({
    url: '/api/order/member/find',
    method: 'post',
    data: params,
  });
}

// 获取用户列表
export async function queryMemberBack(params) {
  return request({
    url: '/api/user/member/queryMemberBack',
    method: 'post',
    data: params
  });
}

// 获取收入明细列表
export async function incomeList(params) {
  return request({
    url: '/api/order/income/find',
    method: 'post',
    data: createFormData(params)
  });
}

// 查看收入明细
export async function incomeDetail(params) {
  return request({
    url: '/api/order/income/findDetail',
    method: 'post',
    data: params
  });
}

// 导出收入信息
export async function exportIncome(params) {
  return request({
    url: '/api/order/income/export',
    method: 'post',
    data: createFormData(params)
  });
}

// 退款
export async function refundMoney(params) {
  return request({
    url: `/api/order/refund/${params.id}`,
    method: 'post',
    data: createFormData(params)
  });
}

// 查看授权信息
export async function getAuthPprotocol(params) {
  return request({
    url: `/api/system/attach/auth_protocol?${createSearch(params)}`,
    method: 'get'
  });
}

