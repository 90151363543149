import { bindNameSpace, genActions } from '@/utils/utils';

export const NAME_SPACE = 'global';

export const TYPES = {
  ERROR: 'error',
};

export const ACTION_TYPES = bindNameSpace(NAME_SPACE, TYPES);

export const ACTIONS = genActions(ACTION_TYPES);
