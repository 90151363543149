import {
  recommendList,
  recommendGet,
  recommendSave,
  recommendUpdate,
  recommendSetstatus,
} from '@/services/siteManage';
// import { message } from 'antd';

const Model = {
  namespace: 'siteManage',
  state: {},
  effects: {
    *recommendList({ payload }, { call }) {
      // 获取员列表
      try {
        const response = yield call(recommendList, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *recommendGet({ payload }, { call }) {
      // 根据主键值获取信息
      try {
        const response = yield call(recommendGet, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *recommendSave({ payload }, { call }) {
      // 添加资源位信息
      try {
        const response = yield call(recommendSave, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *recommendUpdate({ payload }, { call }) {
      // 修改资源位信息
      try {
        const response = yield call(recommendUpdate, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *recommendSetstatus({ payload }, { call }) {
      // 修改状态
      try {
        const response = yield call(recommendSetstatus, payload);
        console.log('response', response);
        return response || {};
      } catch (err) {
        console.log(err);
      }
    },
  },
  reducers: {
    setData(state, action) {
      return {
        ...state,
        [action.payload.dataName]: action.payload.data,
      };
    },
  },
};
export default Model;
