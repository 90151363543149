import { NAME_SPACE, TYPES } from '@/actions/global';
import { queryMenuList } from '@/services/menu';
import { queryNotices } from '@/services/user';
import { logerToken } from '@/utils/token';
import { toastError } from '@/utils/utils';
import { whiteList } from '@/utils/_utils';
import router from 'umi/router';
import { userQuerypermission } from '@/services/internalManage';

const { ERROR } = TYPES;

const GlobalModel = {
  namespace: NAME_SPACE,
  state: {
    collapsed: false,
    notices: [],
    menuList: [],
  },
  effects: {
    *fetchNotices(_, { call, put, select }) {
      const data = yield call(queryNotices);
      yield put({
        type: 'saveNotices',
        payload: data,
      });
      const unreadCount = yield select(
        state => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: data.length,
          unreadCount,
        },
      });
    },

    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count = yield select(state => state.global.notices.length);
      const unreadCount = yield select(
        state => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },

    *changeNoticeReadState({ payload }, { put, select }) {
      const notices = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };

          if (notice.id === payload) {
            notice.read = true;
          }

          return notice;
        }),
      );
      yield put({
        type: 'saveNotices',
        payload: notices,
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter(item => !item.read).length,
        },
      });
    },
    *getMenuData({ payload }, { call, put }) {
      const response = yield call(userQuerypermission, payload);
      console.log('userQuerypermission', response);
      const { data } = response;

      // let data = [
      //   {
      //     "action":"/pomeloJuiceSite",
      //     "children": [
      //       // {
      //       //   "action":"/pomeloJuiceSite",
      //       // }
      //     ]
      //   },
      //   {
      //     "action":"/musicLibrary",
      //     "children": [
      //       {
      //         "action":"/musicLibrary/musicList",
      //       },
      //       {
      //         "action":"/musicLibrary/copyrightOwners",
      //       },
      //       {
      //         "action":"/musicLibrary/personList",
      //       },
      //       {
      //         "action":"/musicLibrary/categoryManage",
      //       }
      //     ]
      //   },
      //   {
      //     "action":"/financialManage",
      //     "children": [
      //       {
      //         "action":"/financialManage/orderList",
      //       },
      //       {
      //         "action":"/financialManage/incomeList",
      //       }
      //     ]
      //   },
      //   {
      //     "action":"/userManage",
      //     "children": [
      //       {
      //         "action":"/userManage/userList",
      //       },
      //     ]
      //   },
      //   {
      //     "action":"/siteManage",
      //     "children": [
      //       {
      //         "action":"/siteManage/resource",
      //       }
      //     ]
      //   },
      //   {
      //     "action":"/internalManage",
      //     "children": [
      //       {
      //         "action":"/internalManage/account",
      //       }
      //     ]
      //   },
      // ];

      let permissions = data;

      let flag = false;

      const tailoring = str => {
        let s = str
          .replace('/', '')
          .toLocaleLowerCase()
          .split('/');
        return s[0] + s[1];
      };

      const pathname = tailoring(location.pathname);

      const checkPath = (path, perm) => {
        for (var i = 0; i < perm.length; i++) {
          if (tailoring(perm[i].menuUrl) == path) {
            flag = true;
          }
          if (perm[i].children) {
            checkPath(path, perm[i].children);
          }
        }
      };

      pathname && checkPath(pathname, permissions);

      for (var j = 0; j < whiteList().length; j++) {
        if (
          tailoring(whiteList()[j])
            .toLocaleLowerCase()
            .replace(/\//g, '') == pathname
        ) {
          flag = true;
        }
      }

      // 不太稳...

      if (pathname && !flag && pathname != 'userlogin' && pathname != 'person_infoindex') {
        router.push('/permission/index');
      }

      if (pathname === 'undefined' || pathname == 'userlogin') {
        // data[0].children.length ? router.push(data[0].children[0].menuUrl) : router.push(data[0].menuUrl);
        router.push(data[0].children.length ? data[0].children[0].menuUrl : data[0].menuUrl);
      }

      if (data) {
        yield put({
          type: 'getMenuList',
          data,
        });
      } else {
        toastError('菜单权限获取失败!');
      }
    },
    *[ERROR]({ payload: { msg, error } }, { call }) {
      if (msg) {
        yield call(toastError, msg);
      }
      console.log(`%c effect error emitted: \n ${error}`, 'color: #f45;font-size: 16px');
    },
  },
  reducers: {
    changeLayoutCollapsed(
      state = {
        notices: [],
        collapsed: true,
      },
      { payload },
    ) {
      return { ...state, collapsed: payload };
    },

    saveNotices(state, { payload }) {
      return {
        collapsed: false,
        ...state,
        notices: payload,
      };
    },

    saveClearedNotices(
      state = {
        notices: [],
        collapsed: true,
      },
      { payload },
    ) {
      return {
        collapsed: false,
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
    getMenuList(state, { data }) {
      return { ...state, menuList: data };
    },
  },
  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen(({ pathname, search }) => {
        logerToken();
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};
export default GlobalModel;
