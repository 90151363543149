import { getCommonData, getProvince, getCity } from '@/services/common';

const CommonModel = {
  namespace: 'common',
  state: {
    breadcrumbsData: {},
    queryData: {},
  },
  effects: {
    *changeBreadcrumbs({ payload }, { put }) {
      yield put({
        type: 'setData',
        payload: {
          dataName: 'breadcrumbsData',
          data: payload,
        },
      });
    },
    *getCommonData({ payload }, { call, put }) {
      const commonData = yield call(getCommonData, payload);
      yield put({
        type: 'setData',
        payload: {
          dataName: payload.type,
          data: commonData.data,
        },
      });
      return commonData.data;
    },
    *getProvince({ payload }, { call, put }) {
      try {
        const commonData = yield call(getProvince, payload);
        return commonData.data || [];
      } catch (e) {
        throw e;
      }
    },
    *getCity({ payload }, { call, put }) {
      try {
        const commonData = yield call(getCity, payload.id);
        return commonData.data;
      } catch (e) {
        throw e;
      }
    },
    *getAdress({ payload }, { call, all, put }) {
      try {
        const { province, city, area } = yield all({
          province: call(getProvince),
          city: call(getCity, payload.province),
          area: call(getCity, payload.city),
        });
        let proData = province.data.map(item =>
          Object.assign({}, item, { label: item.text, isLeaf: false }),
        );
        let cityData = city.data.map(item =>
          Object.assign({}, item, { label: item.text, isLeaf: false }),
        );
        let areaData = area.data.map(item =>
          Object.assign({}, item, { label: item.text, isLeaf: true }),
        );
        cityData.map(item => {
          if (item.value == payload.city) {
            item.children = areaData;
          }
        });
        proData.map(item => {
          if (item.value == payload.province) {
            item.children = cityData;
          }
        });
        return proData;
      } catch (e) {
        throw e;
      }
    },
    *downloadTemplate({ payload }, { call }) {
      try {
        const respones = yield call(studentAPI.downloadTemplate, payload);
        const content = respones;
        const blob = new Blob([content], {
          type: 'application/ms-excel',
        });
        const fileName = `批量下载模板.xlsx`;

        if ('download' in document.createElement('a')) {
          // 非IE下载
          if ('msSaveOrOpenBlob' in navigator) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = window.URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            window.URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }
        } else {
          // IE10+下载
          if ('msSaveOrOpenBlob' in navigator) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            window.navigator.msSaveBlob(blob, fileName);
          }
        }
      } catch (e) {
        throw e;
      }
    },
  },
  reducers: {
    setData(state, action) {
      return {
        ...state,
        [action.payload.dataName]: action.payload.data,
      };
    },
    saveQueryData(state, { payload }) {
      return { ...state, queryData: { ...payload } };
    },
  },
};
export default CommonModel;
