/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import { createAction } from 'redux-actions';
import camelCase from 'lodash/camelCase';
import { parse } from 'querystring';
import { message } from 'antd';

const enc = window.encodeURIComponent;
const dec = window.decodeURIComponent;

/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const createFormData = data => {
  const formData = new FormData();
  for (const i in data) {
    const value = data[i];
    formData.append(i, typeof value === 'string' ? value.trim() : value);
  }
  return formData;
};

export function createSearch(query) {
  // url参数拼接
  if (typeof query !== 'object') return '';

  return Object.keys(query)
    .filter(key => {
      const value = query[key];
      if (typeof value === 'string') return value.trim() && value !== 'undefined';
      if (value instanceof Array && value.length === 0) return;
      return value !== undefined && value !== null;
    })
    .map(key => `${key}=${enc(query[key])}`)
    .join('&');
}

export function searchQuery(search) {
  // 获取url参数
  const queryStr = search.split('?').pop();
  if (!queryStr) return {};
  return queryStr.split('&').reduce((prev, cur) => {
    const [key, value] = cur.split('=');
    return {
      ...prev,
      [key]: dec(value),
    };
  }, {});
}

export function checkNum(str) {
  //校验手机号
  if (str.length > 11 || (str !== '' && !Number(str))) {
    return true;
  }
}

export function checkSC(str) {
  // 校验特殊字符
  let patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
  if (!patrn.test(str)) {
    //如果不包含特殊字符返回false

    return false;
  }

  return true;
}

export const deBounce = (func, wait) => {
  let timeOut = null;
  return function(...args) {
    clearTimeout(timeOut); //一定要清除定时器
    timeOut = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

export const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

export const toastError = (msg, duration = 2) => {
  message.error(msg, duration);
};

export const toastSuccess = (msg, duration = 2) => {
  message.success(msg, duration);
};

export const ONE_SECOND = 1000;

export const ONE_HOUR = 60 * 60 * 1000;

export const ONE_DAY = 24 * ONE_HOUR;

export const ONE_WEEK = 7 * ONE_DAY;

export const ONE_MONTH = 30 * ONE_DAY;

export const WEEK_MAP = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  0: '日',
};

export const genDates = (start, length) => {
  const _start = moment(start).startOf('day');
  const range = Array.from({ length });
  const dates = range.map((_, index) => {
    const timestamp = _start + index * ONE_DAY;
    const date = moment(timestamp).format('YYYY-MM-DD');
    return {
      timestamp,
      date,
      short: moment(timestamp).format('MM.DD'),
      week: `星期${WEEK_MAP[moment(timestamp).day()]}`,
      index,
    };
  });
  return dates;
};

export const bindNameSpace = (namespace, TYPES) => {
  return Object.keys(TYPES).reduce((prev, key) => {
    prev[key] = `${namespace}/${TYPES[key]}`;
    return prev;
  }, {});
};

export const genActions = ACTION_TYPES => {
  return Object.keys(ACTION_TYPES).reduce((actions, key) => {
    actions[camelCase(key)] = createAction(ACTION_TYPES[key]);
    return actions;
  }, {});
};

export const startOfWeek = ms => {
  return moment(ms)
    .startOf('week')
    .valueOf();
};

export const noop = () => {};

export const numberReg = /^\d+(\.\d+)?$/; // 数字验证
export const wordReg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/; // 字符验证

// 下拉框数据处理
export const dataSchema = (data, keyName, valueName, other) => {
  return (data || []).map(item => {
    if (other) {
      return {
        value: item[keyName],
        label: item[valueName],
        [other]: item[other],
      };
    } else {
      return {
        value: item[keyName],
        label: item[valueName],
      };
    }
  });
};

export const kvToValueLabel = (target = {}, keyName = 'label', valueName = 'value') => {
  return Object.keys(target).map(key => {
    return {
      [keyName]: target[key],
      [valueName]: key,
    };
  });
};

// 时间戳转日期
export const formatDate = (date, type) => {
  return moment(date).format(type);
};

// 下载函数
export const downLoad = (content) => {
  const blob = new Blob([content], {
    type: "application/ms-excel"
  });
  const fileName = `批量下载模板.xlsx`;


  if ("download" in document.createElement("a")) {
    // 非IE下载
    if ("msSaveOrOpenBlob" in navigator) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = window.URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      window.URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    }
  } else {
    // IE10+下载
    if ("msSaveOrOpenBlob" in navigator) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      window.navigator.msSaveBlob(blob, fileName);
    }
  }
};

// 对select 数据进行处理
export const changeLabelValue = (option=[], keyName = 'label', valueName = 'value') => {
  let arr =[];
  option.map( (item) => {
    arr.push({
      label: item[keyName],
      value: item[valueName]
    });
  } );
  return arr;
};

// 文件下载(浏览器下载)
export const downloadFile = (url, fileName) => {
  var link = document.createElement('a');
  link.download = fileName;
  link.style.display = 'none';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};

