/* eslint-disable consistent-return */
import cookie from 'component-cookie';
import axios from 'axios';

const TokenKey = 'saas-user-token';
const maxTime = 30000 * 60 * 60 * 1000;
// 获取token
export function getToken() {
  const token = cookie(TokenKey) ? cookie(TokenKey) : null;
  return token;
}

// 设置token
export function setToken(token, expiryTime) {
  cookie(TokenKey, token, { maxage: expiryTime, path: '/' });
}

// 用户操作时 延长token时间
export function logerToken() {
  const token = getToken();
  if (token) {
    cookie(TokenKey, token, { maxage: maxTime, path: '/' });
  }
}

// 移除token
export function removeToken() {
  cookie(TokenKey, null, { path: '/' });
  localStorage.removeItem('user-data');
  localStorage.removeItem('user-list');
}

// 判断是否登录
export function isLogin() {
  return !!getToken();
}

// 判断是否需要登录
export function needLogin() {
  const token = getToken();
  if (token) {
    // 设置请求的token
    axios.defaults.headers.common.Authorization = token;
    return true;
  } else {// 没有token
  // 跳转到登录页面
    window.location.href = '/user/login';}
}
