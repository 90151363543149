/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prefer-const */
import axios from 'axios';
import { isLogin, getToken, removeToken, needLogin } from '@/utils/token';
// import monitor from '@/utils/monitor'
export function createRequest(baseURL = '', timeout) {
  const service = axios.create({
    baseURL, // api的base_url
    timeout: timeout || 10000, // request timeout
  });
  // request interceptor
  service.interceptors.request.use(
    config => {
      // config.iS = new Date();
      // Do something before request is sent
      if (isLogin()) {
        const token = getToken();
        config.headers = { 'token': token, 'Pragma': 'no-cache' };
      } else {
        // removeToken();
        // needLogin();
      }
      return config;
    },
    error => {
      // Do something with request error
      console.error(error); // for debug
      Promise.reject(error);
    },
  );
  // respone interceptor
  service.interceptors.response.use(
    res => {
      console.log('接口', res);
      if(res.data && res.data.meta.success) {
        return res.data;
      } else if(res.data.meta && res.data.meta.code == 40005) {
        removeToken();
        needLogin();

      }else if(res.data.meta && res.data.meta.code == 40008) {
        removeToken();
        // needLogin();
        return Promise.reject(res.data.meta.message || {message: '未知错误'});

      } else {
        return Promise.reject(res.data.meta.message || {message: '未知错误'});
      }
    },
    err => {
      const { response = {} } = err;
      if (!response.status) {
        response.message = '请求异常，请稍后重试';
        return Promise.reject(response);
      }
      let { status, data = {} } = response;
      status -= 0;
      if (status === 401 || status === 403) {
        removeToken();
        needLogin();
      }
      if (status === 429) {
        data.message = '服务繁忙，请稍后再试';
      }
      if (status >= 500) {
        data.message = '网络错误，请检查网络连接后重试';
      }
      if (data.message && data.message.indexOf('timeout') !== -1) {
        data.message = '服务响应超时，请稍后再试';
      }
      return Promise.reject(data);
    },
  );
  return service;
}
