import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/cc/music-pc-manage/src/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    routes: [
      {
        name: 'login',
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__login" */ '../user/login'),
              LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                .default,
            })
          : require('../user/login').default,
        exact: true,
      },
    ],
  },
  {
    path: '/permission',
    routes: [
      {
        name: 'permission',
        path: '/permission/index',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__permission__index" */ '../permission/index'),
              LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                .default,
            })
          : require('../permission/index').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__SecurityLayout" */ '../../layouts/SecurityLayout'),
          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/SecurityLayout').default,
    routes: [
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        routes: [
          {
            path: '/',
            redirect: '/pomeloJuiceSite',
            exact: true,
          },
          {
            path: '/person_info',
            name: 'personInfo',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/person_info',
                redirect: '/person_info/index',
                exact: true,
              },
              {
                path: '/person_info/index',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../person_info/index.jsx'),
                      LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../person_info/index.jsx').default,
                exact: true,
              },
            ],
          },
          {
            path: '/pomeloJuiceSite',
            name: 'pomeloJuiceSite',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/pomeloJuiceSite',
                redirect: '/pomeloJuiceSite/index',
                exact: true,
              },
              {
                path: '/pomeloJuiceSite/index',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../pomeloJuiceSite/index.jsx'),
                      LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../pomeloJuiceSite/index.jsx').default,
                exact: true,
              },
            ],
          },
          {
            path: '/musicLibrary',
            name: 'musicLibrary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/musicLibrary',
                redirect: '/musicLibrary/musicList',
                exact: true,
              },
              {
                path: '/musicLibrary/musicList',
                name: 'musicList',
                routes: [
                  {
                    path: '/musicLibrary/musicList',
                    redirect: '/musicLibrary/musicList/index',
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/musicList/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/musicList/index.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/musicList/index.jsx').default,
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/musicList/edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/musicList/edit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/musicList/edit.jsx').default,
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/musicList/audit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/musicList/audit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/musicList/audit.jsx').default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/musicLibrary/copyrightOwners',
                name: 'copyrightOwners',
                routes: [
                  {
                    path: '/musicLibrary/copyrightOwners',
                    redirect: '/musicLibrary/copyrightOwners/index',
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/copyrightOwners/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/copyrightOwners/index.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/copyrightOwners/index.jsx')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/musicLibrary/personList',
                name: 'personList',
                routes: [
                  {
                    path: '/musicLibrary/personList',
                    redirect: '/musicLibrary/personList/index',
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/personList/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/personList/index.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/personList/index.jsx').default,
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/personList/edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/personList/edit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/personList/edit.jsx').default,
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/personList/audit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/personList/audit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/personList/audit.jsx').default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/musicLibrary/categoryManage',
                name: 'categoryManage',
                routes: [
                  {
                    path: '/musicLibrary/categoryManage',
                    redirect: '/musicLibrary/categoryManage/index',
                    exact: true,
                  },
                  {
                    path: '/musicLibrary/categoryManage/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../musicLibrary/categoryManage/index.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../musicLibrary/categoryManage/index.jsx')
                          .default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/financialManage',
            name: 'financialManage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/financialManage',
                redirect: '/financialManage/orderList',
                exact: true,
              },
              {
                path: '/financialManage/orderList',
                name: 'orderList',
                routes: [
                  {
                    path: '/financialManage/orderList',
                    redirect: '/financialManage/orderList/index',
                    exact: true,
                  },
                  {
                    path: '/financialManage/orderList/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../financialManage/orderList/index.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../financialManage/orderList/index.jsx')
                          .default,
                    exact: true,
                  },
                  {
                    path: '/financialManage/orderList/edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../financialManage/orderList/edit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../financialManage/orderList/edit.jsx')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/financialManage/incomeList',
                name: 'incomeList',
                routes: [
                  {
                    path: '/financialManage/incomeList',
                    redirect: '/financialManage/incomeList/index',
                    exact: true,
                  },
                  {
                    path: '/financialManage/incomeList/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../financialManage/incomeList/index.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../financialManage/incomeList/index.jsx')
                          .default,
                    exact: true,
                  },
                  {
                    path: '/financialManage/incomeList/detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../financialManage/incomeList/detail.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../financialManage/incomeList/detail.jsx')
                          .default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/userManage',
            name: 'userManage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/userManage',
                redirect: '/userManage/userList',
                exact: true,
              },
              {
                path: '/userManage/userList',
                name: 'userList',
                routes: [
                  {
                    path: '/userManage/userList',
                    redirect: '/userManage/userList/index',
                    exact: true,
                  },
                  {
                    path: '/userManage/userList/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../userManage/userList/index'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../userManage/userList/index').default,
                    exact: true,
                  },
                  {
                    path: '/userManage/userList/edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../userManage/userList/edit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../userManage/userList/edit.jsx').default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/siteManage',
            name: 'siteManage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/siteManage',
                redirect: '/siteManage/resource',
                exact: true,
              },
              {
                path: '/siteManage/resource',
                name: 'resource',
                routes: [
                  {
                    path: '/siteManage/resource',
                    redirect: '/siteManage/resource/index',
                    exact: true,
                  },
                  {
                    path: '/siteManage/resource/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../siteManage/resource/index'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../siteManage/resource/index').default,
                    exact: true,
                  },
                  {
                    path: '/siteManage/resource/edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../siteManage/resource/edit.jsx'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../siteManage/resource/edit.jsx').default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/internalManage',
            name: 'internalManage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BlankLayout'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/BlankLayout').default,
            routes: [
              {
                path: '/internalManage',
                redirect: '/internalManage/account',
                exact: true,
              },
              {
                path: '/internalManage/account',
                name: 'account',
                routes: [
                  {
                    path: '/internalManage/account',
                    redirect: '/internalManage/account/index',
                    exact: true,
                  },
                  {
                    path: '/internalManage/account/index',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../internalManage/account/index'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../internalManage/account/index').default,
                    exact: true,
                  },
                  {
                    path: '/internalManage/account/edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../internalManage/account/edit'),
                          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../internalManage/account/edit').default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                  LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import(/* webpackChunkName: "p__404" */ '../404'),
          LoadingComponent: require('/cc/music-pc-manage/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
