import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { isArray } from 'util';
let CancelToken = axios.CancelToken;
let source = CancelToken.source();
let pending = [];
let removePending = ever => {
  for (let p in pending) {
    if (pending[p].u === ever.url + '&' + ever.method) {
      //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
};
export function timeTransformation(t) {
  //时间戳转成 yy-mm-dd hh:mm:ss
  let d = new Date(t);
  var year = d.getFullYear();
  var mouth = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
  var date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
  var hour = d.getHours() > 9 ? d.getHours() : '0' + d.getHours();
  var minute = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes();
  var second = d.getSeconds() > 9 ? d.getSeconds() : '0' + d.getSeconds();
  return year + '-' + mouth + '-' + date + ' ' + hour + ':' + minute + ':' + second;
}

export function getCurrentTime(t) {
  // 时间戳转成 hh:mm:ss
  let d = new Date(t);
  var hour = d.getHours() > 9 ? d.getHours() : '0' + d.getHours();
  var minute = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes();
  var second = d.getSeconds() > 9 ? d.getSeconds() : '0' + d.getSeconds();
  return hour + ':' + minute + ':' + second;
}

export function formatDuring(mss) {
  // 时间差转化成分秒
  var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = (mss % (1000 * 60)) / 1000;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 && seconds >= 1 ? '0' + seconds : seconds;
  return `${hours > 0 ? `${hours}时` : ''} ${
    minutes > 0 ? `${minutes}分 ` : `${hours > 0 ? '00 ' : ''}`
  }${parseInt(seconds)}秒`;
}

export function checkPhone(phone) {
  if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
    return false;
  }
  return true;
}

export function Addbreadcrumbs(dispatch, routes, title) {
  // 自定义面包屑
  // document.querySelector('.child-active').parentNode.classList.add("ant-menu-item-selected");
  dispatch({
    type: 'common/changeBreadcrumbs',
    payload: {
      routes: routes,
      title: title,
    },
  });
}

export function formatByte(limit) {
  var size = '';
  if (limit < 1024) {
    //小于0.1KB，则转化成B
    size = limit.toFixed(1) + 'B';
  } else if (limit < 1024 * 1024) {
    //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(1) + 'KB';
  } else if (limit < 1024 * 1024 * 1024) {
    //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(1) + 'MB';
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
  }

  var sizeStr = size + ''; //转成字符串
  var index = sizeStr.indexOf('.'); //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 1); //获取小数点后两位的值
  if (dou == '0') {
    //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 2, 2);
  }
  return size;
}

export function whiteList() {
  // 路由白名单
  return ['/jiaowu/person_info/index', '/pomeloJuiceSite/index'];
}

export function getDictionaryInfo(dispatch, types) {
  // 字典调用函数
  if (!isArray(types)) return;
  let fn = types.map(item => {
    return new Promise(resolve => {
      dispatch({
        type: 'common/getCommonData',
        payload: { type: item },
      }).then(res => {
        resolve({ [item]: res });
      });
    });
  });
  return Promise.all(fn);
}
// 文件流下载
export function getFile(url, processCallback) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer',
      cancelToken: source.token,
      onDownloadProgress: progress => {
        const { loaded } = progress;
        let obj = { [url]: loaded };
        processCallback && processCallback(obj);
      },
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(error => {
        reject(error.toString());
      });
  });
}

export function handleBatchDownload(array, name, processCallback, success, fail) {
  const data = array; // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
  const zip = new JSZip();
  const cache = {};
  const promises = [];
  data.forEach(item => {
    const promise = getFile(item.privateUrl, processCallback).then(data => {
      // 下载文件, 并存成ArrayBuffer对象
      var file_name = `${item.name}`; // 获取文件名
      zip.file(file_name, data, { binary: true }); // 逐个添加文件
      cache[file_name] = data;
    });
    promises.push(promise);
  });
  Promise.all(promises).then(() => {
    zip
      .generateAsync({ type: 'blob' })
      .then(content => {
        // 生成二进制流
        saveAs(content, '' + name + '.zip'); // 利用file-saver保存文件
        success();
      })
      .catch(err => {
        fail(err);
      });
  });
}

export function cancelRequest() {
  source.cancel();
}
