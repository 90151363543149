import request from '@/services/request';
import { createSearch, createFormData } from '@/utils/utils';

// 账号密码登陆
export async function accountLogin(params) {
  return request({
    url: `/api/user/account/passwordLogin`,
    method: 'post',
    data: createFormData(params),
  });
}

// 获取验证码
export async function getCaptcha(params) {
  return request({
    url: `/api/user/account/sendCode`,
    method: 'post',
    data: createFormData(params),
  });
}

// 账号验证码登陆
export async function codeLogin(params) {
  return request({
    url: `/api/user/account/codeLogin`,
    method: 'post',
    data: createFormData(params),
  });
}

// 退出登录
export async function logout () {
  return request({
    url: `/api/user/account/logout`,
    method: 'post'
  });
}

// 修改密码
export async function resetPassWord (params) {
  return request({
    url: `/api/user/account/forgetPwd`,
    method: 'post',
    data: createFormData(params)
  });
}
