/**
 * app.js 默认为umi运行时配置文件,优先执行，可以修改运行时参数
 */
import { reducer as modalReducer } from 'redux-modal';
import './vendor/expand/array';

export const dva = {
  config: {
    onError(e) {
      console.error(e.message);
    },
    extraReducers: {
      modal: modalReducer,
    },
  },
  plugins: [
    // require('dva-logger')(),
  ],
};

window.addEventListener('unhandledrejection', event => {
  // if (event.reason && event.reason.code && event.reason.code > 9000 && event.reason.code < 9999) {
  // }
});
