import {
  musicList,
  musicApply,
  copyRightOwnerList,
  addCopyRightOwner,
  musiciansList,
  addMusicians,
  composerList,
  addDictionary,
  queryMusicList,
  findDictionary,
  setMusicStatus,
  getCopyRightOwnerById,
  getMusiciansById,
  findAllMusicians,
  getMusicDetail,
  updateMusic,
  updateCopyRightOwner,
  findAllCopyRightOwner,
  updateMusicians,
  updateDictionary,
  findDictionaryById,
  setDictionaryStatus,
  setMusicOrderNum,
  setMusicTopStatus,
  findMusicTpeMood,
  setMusicPrice,
  getMusicPrice,
  findAllComposer,
  reviewComposter,
  setMusicAudit,
  getComposer,
  getAuth_protocol,
  queryStatistic,
  changeCoCreator
} from '@/services/musicLibrary';
import { message } from 'antd';

const Model = {
  namespace: 'musicLibrary',
  state: {
  },
  effects: {
    *musicList({ payload }, { call }) {
      // 查询账号列表
      try {
        const response = yield call(musicList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },

    *queryMusicList({ payload }, { call }) {
      // 查询账号列表
      try {
        const response = yield call(queryMusicList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *musicApply({ payload }, { call }) {
      // 查询账号列表
      try {
        const response = yield call(musicApply, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *setMusicOrderNum({ payload }, { call }) {
      // 设置音乐排列号
      try {
        const response = yield call(setMusicOrderNum, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *setMusicTopStatus({ payload }, { call }) {
      // 设置音乐是否置顶
      try {
        const response = yield call(setMusicTopStatus, payload);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *setMusicPrice({ payload }, { call }) {
      // 设置音乐报价
      try {
        const response = yield call(setMusicPrice, payload);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *getMusicPrice({ payload }, { call }) {
      try {
        const response = yield call(getMusicPrice, payload);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *copyRightOwnerList({payload}, {call}){
      // 版权方列表
      try {
        const response = yield call(copyRightOwnerList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *addCopyRightOwner({payload}, {call}){
      // 版权方列表
      try {
        const response = yield call(addCopyRightOwner, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *musiciansList({payload}, {call}){
      // 版权方列表
      try {
        const response = yield call(musiciansList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *addMusicians({payload}, {call}){
      // 版权方列表
      try {
        const response = yield call(addMusicians, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *composerList({payload}, {call}){
      // 合作创作者
      try {
        const response = yield call(composerList, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *addDictionary({payload}, {call}){
      // 合作创作者
      try {
        const response = yield call(addDictionary, payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *findDictionary ({payload}, {call}){
      // 字典数据分页
      try {
        const response = yield call(findDictionary , payload);
        console.log('response', response);

        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *setMusicStatus({payload}, {call}){
      // 设置音乐上下架状态
      try {
        const response = yield call(setMusicStatus , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *getCopyRightOwnerById ({payload}, {call}){
      //根据ID获取版权方信息
      try {
        const response = yield call(getCopyRightOwnerById , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *getMusiciansById ({payload}, {call}){
      //根据ID获取版权方信息
      try {
        const response = yield call(getMusiciansById , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *findAllMusicians({payload}, {call}){
      //获取全部音乐人字典
      try {
        const response = yield call(findAllMusicians , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *findAllComposer({payload}, {call}){
      //获取全部合作创作者
      try {
        const response = yield call(findAllComposer , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *getMusicDetail({payload}, {call}){
      //音乐编辑信息
      try {
        const response = yield call(getMusicDetail , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *updateMusic({payload}, {call}){
      //编辑更新音乐
      try {
        const response = yield call(updateMusic , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *updateCopyRightOwner({payload}, {call}){
      //编辑版权方
      try {
        const response = yield call(updateCopyRightOwner , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *findAllCopyRightOwner({payload}, {call}){
      //获取所有版权方
      try {
        const response = yield call(findAllCopyRightOwner , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *updateMusicians({payload}, {call}){
      //获取所有版权方
      try {
        const response = yield call(updateMusicians , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *updateDictionary({payload}, {call}){
      //修改字典
      try {
        const response = yield call(updateDictionary , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *findDictionaryById({payload}, {call}){
      //根据ID获取某一条字典数据
      try {
        const response = yield call(findDictionaryById , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *setDictionaryStatus({payload}, {call}){
      //设置字典状态
      try {
        const response = yield call(setDictionaryStatus , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    // 查询情绪&类型
    *findMusicTpeMood({payload}, {call}){
      try {
        const response = yield call(findMusicTpeMood , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *reviewComposter({payload}, {call}){
      try {
        const response = yield call(reviewComposter , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    // 音乐审核
    *setMusicAudit({payload}, {call}){
      try {
        const response = yield call(setMusicAudit , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    // 获取一条合作创作者
    *getComposer({payload}, {call}){
      try {
        const response = yield call(getComposer , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    // 生成授权协议
    *getAuth_protocol({payload}, {call}){
      try {
        const response = yield call(getAuth_protocol , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *queryStatistic({payload}, {call}){
      try {
        const response = yield call(queryStatistic , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },
    *changeCoCreator({payload}, {call}){
      try {
        const response = yield call(changeCoCreator , payload);
        return response.data || {};
      } catch ( err ) {
        console.log(err);
      }
    },

  },
  reducers: {
    setData(state, action) {
      return {
        ...state,
        [action.payload.dataName]: action.payload.data,
      };
    },
  },
};
export default Model;
