import request from '@/services/request';

export async function queryCurrent() {
  return request({
    url: '/api/current',
    method: 'get',
  });
}

export async function fetchCampusList() {
  return request({
    url: '/api/school/manage/current',
    method: 'get',
  });
}

export async function queryNotices() {
  return request('/api/notices');
}

// 获取当前账号信息
export async function queryCurrentUser() {
  return request({
    url: '/api/user/user/queryCurrentUser',
    method: 'get',
  });
}
