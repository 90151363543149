import request from '@/services/request';
import { createFormData } from '@/utils/utils';


export async function uploadImg(params) {
  // 上传图片接口
  return request({
    url: `/api/common/upload`,
    method: 'post',
    data: createFormData(params),
  });
}

export async function getRoleList(schoolCode) {
  return request({
    url: `/api/role/?schoolCode=${schoolCode}`,
    method: 'get',
  });
}

export async function getCommonData (params) { // 根据不同字段获取不同类型数据 主要是下拉框的相关数据
  return request({
    url: `/api/dict/type/${params.type}/`,
    method: 'get'
  });
}

export async function getProvince() { //省
  return request({
    url: `/api/geo/province/`,
    method: 'get',
  });
}

export async function getCity(id) { //市区
  return request({
    url: `/api/geo/${id}/`,
    method: 'get',
  });
}


//下载批量上传模板
export async function downloadTemplate (type) {
  return request({
    url: `/api/common/import_data_template?type=${type}`,
    method: 'get',
    responseType: "blob"
  });
}

// 批量导入学员
export async function batchImportStudents (params) { 
  return request({
    url: `/api/recruit_user/import`,
    method: 'post',
    data:  createFormData(params)
  });
}
