export default {
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  //==============================================
  'menu.jiaowu': '教务管理',
  'menu.jiaowu.student': '学员管理',
  'menu.jiaowu.teacher': '教师管理',
  'menu.jiaowu.account': '账号管理',
  'menu.jiaowu.time_table': '课表管理',
  'menu.jiaowu.classes': '班级管理',
  'menu.other.welcome': '欢迎',
  'menu.zhaosheng': '招生中心',
  'menu.zhaosheng.data': '招生数据',
  'menu.zhaosheng.student': '招生学员',
  'menu.zhaosheng.auditionClass': '试听班管理',
  'menu.jiaowu.campus_manage': '校区管理',
  'menu.zhaosheng.practice': '每日一练',
  'menu.zhaosheng.channel': '渠道管理',
  'menu.personInfo': '个人中心',
  'menu.siteManage': '网站管理',
  'menu.siteManage.resource': '资源位',
  'menu.pomeloJuiceSite': '柚子站点',
  'menu.musicLibrary': '音乐库',
  'menu.musicLibrary.musicList': '音乐列表',
  'menu.musicLibrary.copyrightOwners': '版权方列表',
  'menu.musicLibrary.personList': '音乐人列表',
  'menu.musicLibrary.categoryManage': '分类管理',
  'menu.financialManage': '财务管理',
  'menu.financialManage.incomeList': '收入列表',
  'menu.financialManage.orderList': '订单列表',
  'menu.userManage': '用户管理',
  'menu.userManage.userList': '用户列表',
  'menu.internalManage': '内部管理',
  'menu.internalManage.account': '账号列表',


};
