import { accountLogin, codeLogin, getCaptcha, logout } from '@/services/login';
import { setAuthority } from '@/utils/authority';
import { removeToken, setToken } from '@/utils/token';
import { message } from 'antd';
import { routerRedux } from 'dva/router';
const maxTime = 48 * 60 * 60 * 1000;

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *login({ payload }, { call,put }) {
      // 账号密码登陆
      const response = yield call(accountLogin, payload);
      console.log('response', response);
      if (response.meta.success === true) {
        setToken(response.data, maxTime);
        yield put({
          type: 'global/getMenuData',
        });
      } else {
        message.warning(response.message);
      }
    },

    *codeLogin({ payload }, { call, put }) {
      // 验证码登陆
      const response = yield call(codeLogin, payload);
      console.log('response', response);
      if (response.meta.success === true) {
        setToken(response.data, maxTime);
        // window.location.href = '/';
        yield put({
          type: 'global/getMenuData',
        });
      } else {
        message.warning(response.meta.message);
      }
    },

    *getCaptcha({ payload }, { call }) {
      try {
        const status = yield call(getCaptcha, payload);
        if (!status.meta.success) {
          message.warning(status.message);
        }
        return status;
      } catch (err) {
        message.warning(err);
        return err;
      }
    },

    *logout(_, { call, put }) {
      if (window.location.pathname !== '/user/login') {
        removeToken();
        yield call(logout, {});
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
          }),
        );
      }
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return { ...state, status: payload.success, type: payload.message, payload };
    },
  },
};
export default Model;
