import {
  userList,
  userGet,
  userSave,
  userQuerypermission,
  userUpdate,
  systemPermission,
  userAccounStatus,
  queryPermissionByUserId,
} from '@/services/internalManage';
// import { message } from 'antd';

const Model = {
  namespace: 'internalManage',
  state: {},
  effects: {
    *userList({ payload }, { call }) {
      // 获取员工列表
      try {
        const response = yield call(userList, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userGet({ payload }, { call }) {
      // 根据主键值获取员工信息
      try {
        const response = yield call(userGet, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userSave({ payload }, { call }) {
      // 添加员工信息
      try {
        const response = yield call(userSave, payload);
        console.log('response', response);

        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userQuerypermission({ payload }, { call }) {
      // 添获取当前用户权限
      try {
        const response = yield call(userQuerypermission, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userUpdate({ payload }, { call }) {
      // 修改员工信息
      try {
        const response = yield call(userUpdate, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *systemPermission({ payload }, { call }) {
      // 获取所有权限
      try {
        const response = yield call(systemPermission, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *userAccounStatus({ payload }, { call }) {
      // 获取所有权限
      try {
        const response = yield call(userAccounStatus, payload);
        console.log('response', response);
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
    *queryPermissionByUserId({ payload }, { call, put }) {
      // 获取所有权限
      try {
        const response = yield call(queryPermissionByUserId, payload);
        console.log('response', response);
        yield put({
          type: 'setData',
          payload: {
            dataName: 'permissionsArr',
            data: response.data,
          },
        });
        return response.data || {};
      } catch (err) {
        console.log(err);
      }
    },
  },
  reducers: {
    setData(state, action) {
      return {
        ...state,
        [action.payload.dataName]: action.payload.data,
      };
    },
  },
};
export default Model;
