import { queryCurrent, fetchCampusList, queryCurrentUser,query as queryUsers } from '@/services/user';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
    campusList: [],
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },

    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrentUser);
      response.data.orgName = '柚子运营后台';
      // console.log('queryCurrentUser', response);
      // let response = {
      //   success: true,
      //   message: '',
      //   code: 200,
      //   data: {
      //     id: 1,
      //     orgId: 1,
      //     name: ' 教研',
      //     pictureUrl: '',
      //     avatar:
      //       'https://img.txqn.huohua.cn/beetleImg/2019122756744e4cb827a-d6de-4c27-80b8-1f78cf7c1aff.jpg',
      //     phone: '13312345678',
      //     createdTime: '2019-11-27 08:00:00',
      //     roleName: '机构校长,招生人员',
      //     schoolName: '产研测朝阳中心',
      //     schoolCode: 'SC0000002',
      //     orgName: '柚子后台管理',
      //     expirationDate: '长期有效',
      //     controlLimit: 2,
      //   },
      // };
      const { data = {} } = response || {};
      localStorage.setItem('user-data', JSON.stringify(data));
      yield put({
        type: 'saveCurrentUser',
        payload: data,
      });
    },

    *fetchCampusList(_, { call, put }) {
      // const response = yield call(fetchCampusList);
      // const { data = [] } = response || {};
      // let newData = data.map(item => {
      //   return {
      //     label: item.schoolName,
      //     value: item.schoolCode,
      //   };
      // });
      // localStorage.setItem('user-list', JSON.stringify(newData));
      let newData = [];
      yield put({
        type: 'saveCampusList',
        payload: newData,
      });
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      return { ...state, currentUser: action.payload || {} };
    },
    saveCampusList(state, action) {
      return { ...state, campusList: action.payload || {} };
    },

    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
export default UserModel;
