import request from '@/services/request';
import { createSearch } from '@/utils/utils';

export async function queryEmployeeList(params) {
  // 查询账号列表
  return request({
    url: `/api/employee/page?${createSearch(params)}`,
    method: 'get',
  });
}

export async function queryTreePermission(params) {
  // 查询树状权限
  return request({
    url: `/api/permission/tree_permissions?roleIds=${params.arr}&schoolCode=${params.schoolCode}`,
    method: 'get',
  });
}

export async function createEmployee(params) {
  // 账号管理新增用户
  return request({
    url: `/api/employee/`,
    method: 'post',
    data: params,
  });
}

export async function editEmployee(params) {
  // 根据ID修改用户信息
  return request({
    url: `/api/employee/${params.id}`,
    method: 'put',
    data: params,
  });
}

export async function getUserInfo(params) {
  return request({
    url: `/api/employee/${params.id}`,
    method: 'get',
  });
}

export async function getAllCampusList() {
  return request({
    url: `/api/school/manage/current`,
    method: 'get',
  });
}
