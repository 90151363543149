import request from '@/services/request';
import { createFormData } from '@/utils/utils';

// 获取员工列表
export async function userList(params) {
  return await request({
    url: `/api/user/user/find`,
    method: 'post',
    data: createFormData(params),
  });
}

// 根据主键值获取员工信息
export async function userGet(params) {
  return await request({
    url: `/api/user/user/get`,
    method: 'post',
    data: createFormData(params),
  });
}

// 添加员工信息
export async function userSave(params) {
  return await request({
    url: `/api/user/user/save`,
    method: 'post',
    data: createFormData(params),
  });
}

// 获取当前用户权限
export async function userQuerypermission(params) {
  return await request({
    url: `/api/user/user/queryPermission`,
    method: 'post',
    data: createFormData(params),
  });
}

// 修改员工信息
export async function userUpdate(params) {
  return await request({
    url: `/api/user/user/update`,
    method: 'post',
    data: createFormData(params),
  });
}

// 获取全部权限
export async function systemPermission(params) {
  return await request({
    url: `/api/system/permission/query`,
    method: 'post',
    data: createFormData(params),
  });
}

// 修改账号状态
export async function userAccounStatus(params) {
  return await request({
    url: `/api/user/account/update_enable_status`,
    method: 'post',
    data: params,
  });
}

// 根据用户ID查询员工权限信息
export async function queryPermissionByUserId(params) {
  return await request({
    url: `/api/user/user/queryPermissionByUserId`,
    method: 'post',
    data: createFormData(params),
  });
}
