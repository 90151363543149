import {
  queryEmployeeList,
  queryTreePermission,
  editEmployee,
  getUserInfo,
  getAllCampusList
} from '@/services/account';
import { getRoleList } from '@/services/common';
import { message } from 'antd';

const Model = {
  namespace: 'account',
  state: {
    editData: {
      name: '',
      nickName: '',
      gender: '',
      avatar: '',
      phone: '',
      isTeacher: '',
      status: '',
      roleIds: [],
      selectArr: [],
    },
    treeData: {
      arr: [],
      selectedArr: []
    }
  },
  effects: {
    *queryEmployeeList({ payload }, { call, put }) {
      // 查询账号列表
      // const response = yield call(queryEmployeeList, payload);
      let response = {};
      const { data } = response;
      if (!response.success) {
        message.error(response.message);
      }
      yield put({
        type: 'changeStatus',
        data,
      });
      return data;
    },
    *queryTreePermission({ payload }, { call, put }) {
      // 查询树状
      const response = yield call(queryTreePermission, payload);
      let arr = [];
      let selectedArr = [];
      if (response && response.data) {
        arr = response.data.map((v) => {
          if (v.selectStatus) {
            selectedArr.push(v.id);
          }
          if (v.children) {
            return {
              title: v.title,
              key: v.id,
              children: v.children.map((item) => {
                if (item.selectStatus) {
                  selectedArr.push(item.id);
                }
                return {
                  title: item.title,
                  key: item.id
                };
              })
            };
          } else {
            return {
              title: v.title,
              key: v.id
            };
          }
        });
      }

      const treeData = {
        arr,
        selectedArr
      };
      yield put({
        type: 'getTreePermission',
        treeData,
      });
    },
    *getRoleList({ payload }, { call, put }) {
      // 获取角色列表
      const response = yield call(getRoleList, payload.schoolCode);
      const { data } = response;
      data.map(role => {// 针对搜索组件添加新的属性
        role.value = role.id,
        role.label = role.name;
      });
      yield put({
        type: 'setRoleList',
        data,
      });
      return data;
    },
    // *createEmployee({ payload }, { call }) {
    //   // 账号管理新增用户
    //   const response = yield call(createEmployee, payload);
    //   if (response.success) {
    //     this.props.history.push('/account/manage');
    //   }
    // },
    *getAllCampusList({ payload }, { call }) {
      try {
        // const response = yield call(getAllCampusList, payload);
        // return response.data;
        return [];
      } catch (e) {
        throw e;
      }
    },
    *editEmployee({ payload }, { call }) {
      const response = yield call(editEmployee, payload);
      return response;
    },
    *getUserInfo({ payload }, { call, put }) {
      const response = yield call(getUserInfo, payload);
      const { data } = response;
      if (!response.success) {
        message.error(response.message);
      }
      yield put({
        type: 'setUserDate',
        editData: data,
      });
    },
  },
  reducers: {
    changeStatus(state, { data }) {
      return { ...state, data };
    },
    getTreePermission(state, { treeData }) {
      return { ...state, treeData };
    },
    setRoleList(state, { data }) {
      return { ...state, roleList: data };
    },
    setUserDate(state, { editData }) {
      return { ...state, editData };
    },
  },
};
export default Model;
